import React, {Component} from 'react';
import Layout from '../components/shared/layout';
import TermsAndConditionsComponent from '../components/privacy-policy/terms-and-conditions.component';
import {TabComponent, TabItemDirective, TabItemsDirective} from '@syncfusion/ej2-react-navigations';
import InsuranceProductInformationDocumentComponent
  from "../components/privacy-policy/insurance-product-information-document.component";
import OtherImportantInformationComponent from "../components/privacy-policy/other-important-information.component";
import downloadIcon from '../assets/images/download_icon.png';
import beDualTncPdf from '../assets/pdf/BE_Dual-TNC.pdf';
import beFrIpidPdf from '../assets/pdf/BE_FR-IPID.pdf';
import beNlIpidPdf from '../assets/pdf/BE_NL-IPID.pdf';
import beDualOiiPdf from '../assets/pdf/BE_Dual-OII.pdf';
import nlNlTncPdf from '../assets/pdf/NL_NL-TNC.pdf';
import nlNlIpidPdf from '../assets/pdf/NL_NL-IPID.pdf';
import nlNlOiiPdf from '../assets/pdf/NL_NL-OII.pdf';
import fiFiTncPdf from '../assets/pdf/FI_FI-TNC.pdf';
import fiFiIpidPdf from '../assets/pdf/FI_FI-IPID.pdf';
import fiFiOiiPdf from '../assets/pdf/FI_FI-OII.pdf';
import axios from "axios";
import {graphql} from "gatsby";
import { env } from '../app.constant';

class PrivacyPolicy extends Component {
  headerText = [{ text: "Terms & Conditions" }, { text: "Insurance Product Information Document" }, { text: "Other Important Information" }];
  state = {
    initialFile: 'Terms &amp; Conditions',
  }

  static getDerivedStateFromProps = (props, state) => {
    if(props.location.state && props.location.state.tabIndex === 2){
      state.initialFile = "Other Important Information"
    }
    return null;
  }

  content0() {
    return (<TermsAndConditionsComponent />);
  }
  content1() {
    return (<InsuranceProductInformationDocumentComponent />);
  }
  content2() {
    return (<OtherImportantInformationComponent />);
  }

  render() {
    return (
      <div className="tnc-page">
        <Layout header={true} isBackBtn={true}>
          <div className="save-pdf">
            <p><img src={downloadIcon}  alt="" /><a onClick={this.fetchFile}>Save PDFs</a></p>
          </div>
          <TabComponent onClick={this.handleClickActiveTab} selectedItem={this.props.location.state? this.props.location.state.tabIndex : 0}>
            <TabItemsDirective>
              <TabItemDirective header={this.headerText[0]} content={this.content0} />
              <TabItemDirective header={this.headerText[1]} content={this.content1} />
              <TabItemDirective header={this.headerText[2]} content={this.content2} />
            </TabItemsDirective>
          </TabComponent>
        </Layout>
      </div>
    )
  }

  handleClickActiveTab = e => {
    this.state.initialFile = null
    this.getPdf(e.target.innerHTML)
  }

  getPdf = (param) => {
    document.title = '';
    let locale = window.location.pathname
    if (param === 'Terms &amp; Conditions') {
      switch (env.GATSBY_COUNTRY) {
        case "BE":
          this.state.saveFile = beDualTncPdf
          break;
        case "NL":
          this.state.saveFile = nlNlTncPdf
          break;
        case "FI":
          this.state.saveFile = fiFiTncPdf
          break;
      }
      this.state.fileName = "Terms & Conditions.pdf"
    } else if (param === "Insurance Product Information Document") {
      switch (env.GATSBY_COUNTRY) {
        case "BE":
          if (locale.includes("/be_fr/")) {
            this.state.saveFile = beFrIpidPdf
          }else{
            this.state.saveFile = beNlIpidPdf
          }
          break;
        case "NL":
          this.state.saveFile = nlNlIpidPdf
          break;
        case "FI":
          this.state.saveFile = fiFiIpidPdf
          break;
      }
      this.state.fileName = "Insurance Product Information Document.pdf"
    } else {
      switch (env.GATSBY_COUNTRY) {
        case "BE":
          this.state.saveFile = beDualOiiPdf
          break;
        case "NL":
          this.state.saveFile = nlNlOiiPdf
          break;
        case "FI":
          this.state.saveFile = fiFiOiiPdf
          break;
      }
      this.state.fileName = "Other Important Information.pdf"
    }
  }

  fetchFile = () => {
    if(this.state.initialFile!=null){
      this.getPdf(this.state.initialFile)
    }
    let pdfFile = this.state.saveFile
    let fileName = this.state.fileName
    axios({
      url: pdfFile,
      method: "GET",
      headers: { 'Content-Type': 'application/pdf' },
      responseType: "blob"
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${fileName}`
      );
      document.body.appendChild(link);
      link.click();
    });
  }
}

export default PrivacyPolicy;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;